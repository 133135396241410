export class ApiModel {
  constructor(objectStorage) {
    this.objectStorage = objectStorage;
    this.instanceData = null;
  }
  async login(rawInstanceValue) {
    const instance = this.normalizeInstace(rawInstanceValue);
    this.instanceData = await this.getInstanceData(instance);
    console.log("got instance data: ", this.instanceData);
    if (this.instanceData.access_token == null) {
      await this.obtainCode(instance, this.instanceData);
    } else {
      console.log("already have access token ");
    }
  }
  async getInstanceData(instance) {
    return this.objectStorage.get(instance) ?? await this.createApp(instance);
  }
  async createApp(instance) {
    console.log(`creating app on ${instance}...`);
    const postURL = new URL(instance);
    postURL.pathname += "/api/v1/apps";
    const redirectUri = new URL(window.location.href);
    redirectUri.searchParams.append("instance", instance);
    const appDataResponse = await fetch(postURL.toString(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        client_name: "follow_bot",
        redirect_uris: redirectUri.toString(),
        scopes: "read write follow"
      })
    }).then(unwrapResponse);
    const appData = appDataResponse.json;
    console.log("got response:", appData);
    const instanceData = {instance, app: appData, access_token: null};
    this.objectStorage.set(instance, instanceData);
    return instanceData;
  }
  normalizeInstace(rawInstanceValue) {
    return !rawInstanceValue.startsWith("http") ? "https://" + rawInstanceValue : rawInstanceValue;
  }
  async obtainCode(instance, instanceData) {
    const url = new URL(instance);
    url.pathname += "/oauth/authorize";
    url.searchParams.append("client_id", instanceData.app.client_id);
    url.searchParams.append("client_secret", instanceData.app.client_secret);
    url.searchParams.append("redirect_uri", instanceData.app.redirect_uri);
    url.searchParams.append("response_type", "code");
    url.searchParams.append("scope", "read write follow");
    console.log("opening URL", url.toString());
    window.location.href = url.toString();
  }
  async fetchOauthToken(url) {
    const code = url.searchParams.get("code");
    const instance = url.searchParams.get("instance");
    if (code == null || instance == null) {
      throw new Error("boom " + url.toString());
    }
    const instanceData = this.objectStorage.get(instance);
    if (instanceData == null) {
      throw new Error("boom 2 " + url.toString());
    }
    const fetchURl = new URL(instance);
    fetchURl.pathname += "/oauth/token";
    console.log("fetching oauth token for", fetchURl);
    const redirectUri = instanceData.app.redirect_uri;
    const {json} = await fetch(fetchURl.toString(), {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        client_id: instanceData.app.client_id,
        client_secret: instanceData.app.client_secret,
        redirect_uri: redirectUri,
        grant_type: "authorization_code",
        code
      })
    }).then(unwrapResponse);
    const {access_token} = json;
    console.log("fetched access_token");
    const newInstanceData = {...instanceData};
    newInstanceData.access_token = access_token;
    this.objectStorage.set(instance, newInstanceData);
  }
  async apiFetch(path, method, query = {}) {
    const url = new URL(this.assertInstanceData().instance);
    url.pathname += path;
    for (const [k, v] of Object.entries(query)) {
      if (Array.isArray(v)) {
        for (const item of v) {
          url.searchParams.append(k + "[]", item.toString());
        }
      } else {
        url.searchParams.append(k, v.toString());
      }
    }
    const response = await fetch(url.toString(), {
      method,
      headers: {
        Authorization: `Bearer ${this.assertInstanceData().access_token}`,
        Accept: "application/json"
      }
    });
    return unwrapResponse(response);
  }
  assertInstanceData() {
    const instance = this.instanceData;
    if (instance == null) {
      throw new Error("Not logged in!");
    }
    return instance;
  }
}
async function unwrapResponse(response) {
  if (response.status != 200) {
    throw new Error(`Request failed ${response.status} ${await response.text()}`);
  }
  const json = await response.json();
  const headers = response.headers;
  return {json, headers};
}
