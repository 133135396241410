export class ObjectStorage {
  get(name) {
    const s = localStorage.getItem(name);
    return s == null ? s : JSON.parse(s);
  }
  set(name, value) {
    const s = JSON.stringify(value);
    localStorage.setItem(name, s);
  }
  keys() {
    return Object.keys(localStorage);
  }
}
