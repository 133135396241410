import {h} from "../_snowpack/pkg/preact.js";
import {useEffect, useRef} from "../_snowpack/pkg/preact/hooks.js";
export function DatePicker({date, onDateSelected}) {
  const inputEl = useRef(null);
  useEffect(() => {
    if (inputEl.current)
      inputEl.current.valueAsDate = date;
  }, []);
  const initInput = (input) => {
    inputEl.current = input;
    if (input)
      input.valueAsDate = date;
  };
  return /* @__PURE__ */ h("div", null, /* @__PURE__ */ h("input", {
    type: "date",
    ref: initInput
  }), /* @__PURE__ */ h("button", {
    style: "margin: 0 8px;",
    className: "text-button",
    onClick: () => inputEl.current?.valueAsDate && onDateSelected(inputEl.current.valueAsDate)
  }, "Filter"));
}
