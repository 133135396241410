import {h} from "../_snowpack/pkg/preact.js";
import {useCallback} from "../_snowpack/pkg/preact/hooks.js";
import {AccountInfo} from "./AccountInfo.js";
function stopPropagation(fn) {
  return useCallback((e) => {
    e.stopPropagation();
    fn();
  }, [fn]);
}
export function MovedAccountItem(props) {
  const {oldAccount, newAccount} = props.account;
  const unfollow = stopPropagation(props.onUnfollow);
  const removeFromFollowers = stopPropagation(props.onRemoveFromFollowers);
  const follow = stopPropagation(props.onFollow);
  return /* @__PURE__ */ h("div", {
    className: "item"
  }, /* @__PURE__ */ h(AccountInfo, {
    account: oldAccount.account,
    relationship: oldAccount.relationship
  }, /* @__PURE__ */ h("div", {
    className: "expander"
  }), /* @__PURE__ */ h("div", {
    className: "item-buttons"
  }, /* @__PURE__ */ h("button", {
    className: "text-button",
    onClick: unfollow,
    disabled: !oldAccount.relationship.following
  }, "Unfollow"), /* @__PURE__ */ h("button", {
    className: "text-button",
    onClick: removeFromFollowers,
    disabled: !oldAccount.relationship.followed_by
  }, "Remove from followers"))), /* @__PURE__ */ h("div", {
    className: "secondary"
  }, "has moved to"), /* @__PURE__ */ h(AccountInfo, {
    account: newAccount.account,
    relationship: newAccount.relationship
  }, /* @__PURE__ */ h("div", {
    className: "expander"
  }), /* @__PURE__ */ h("div", {
    className: "item-buttons"
  }, /* @__PURE__ */ h("button", {
    className: "text-button",
    onClick: follow,
    disabled: newAccount.relationship.following || newAccount.relationship.requested
  }, "Follow"))));
}
