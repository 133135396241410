import {h} from "../_snowpack/pkg/preact.js";
import {useEffect, useState} from "../_snowpack/pkg/preact/hooks.js";
import {MovedAccountList} from "./MovedAccountList.js";
import {LoginForm} from "./LoginForm.js";
import {FollowingAccountList} from "./FollowingAccountList.js";
import {FollowerAccountList} from "./FollowerAccountList.js";
export function App(props) {
  const [state, setState] = useState({type: "notLoggedIn", loading: false});
  async function loginAndLoad(instance) {
    setState({type: "notLoggedIn", loading: true});
    try {
      await props.controller.loginAndFetch(instance);
      setState({type: "chooser"});
    } catch {
      setState({type: "notLoggedIn", loading: false});
    }
  }
  useEffect(() => {
    setState({type: "notLoggedIn", loading: true});
    props.controller.handleOauthRedirect().then((instance) => {
      if (instance) {
        loginAndLoad(instance);
      } else {
        setState({type: "notLoggedIn", loading: false});
      }
    });
  }, []);
  function renderInner() {
    switch (state.type) {
      case "notLoggedIn":
        return /* @__PURE__ */ h("div", null, /* @__PURE__ */ h("div", {
          className: "indefinite-progress",
          style: state.loading ? "" : "opacity: 0;"
        }), /* @__PURE__ */ h(LoginForm, {
          objectStorage: props.objectStorage,
          onLogin: loginAndLoad
        }));
      case "chooser":
        return renderChooser();
      case "moved":
        return renderList();
      case "following":
        return renderFollowingAccountList(state);
      case "followers":
        return renderFollowersAccountList(state);
      default:
        throw new Error("unknown state");
    }
  }
  function renderList() {
    return /* @__PURE__ */ h(MovedAccountList, {
      controller: props.controller
    });
  }
  function renderFollowingAccountList(state2) {
    return /* @__PURE__ */ h(FollowingAccountList, {
      controller: props.controller,
      inactiveSince: state2.inactiveSince
    });
  }
  function renderFollowersAccountList(state2) {
    return /* @__PURE__ */ h(FollowerAccountList, {
      controller: props.controller,
      inactiveSince: state2.inactiveSince
    });
  }
  async function openMoved() {
    setState({type: "moved"});
  }
  function renderChooser() {
    return /* @__PURE__ */ h("div", {
      className: "chooser"
    }, /* @__PURE__ */ h("button", {
      className: "button",
      onClick: openMoved
    }, "Moved"), /* @__PURE__ */ h("button", {
      className: "button",
      onClick: () => setState({type: "following", inactiveSince: new Date("2021-1-1")})
    }, "Following"), /* @__PURE__ */ h("button", {
      className: "button",
      onClick: () => setState({type: "followers", inactiveSince: new Date("2021-1-1")})
    }, "Followers"));
  }
  return /* @__PURE__ */ h("div", {
    className: "App"
  }, renderInner());
}
