import {h} from "../_snowpack/pkg/preact.js";
import {useEffect, useReducer, useState} from "../_snowpack/pkg/preact/hooks.js";
import {MovedAccountItem} from "./AccountItem.js";
import {ProgressIndicator} from "./ProgressIndicator.js";
function accountsReducer(state, action) {
  switch (action.type) {
    case "addAccounts":
      return state.concat(action.accounts);
    case "relationshipUpdated": {
      return state.map((pair) => {
        if (pair.oldAccount.account.id === action.accountId) {
          return {
            oldAccount: {account: pair.oldAccount.account, relationship: action.relationship},
            newAccount: pair.newAccount
          };
        } else if (pair.newAccount.account.id === action.accountId) {
          return {
            oldAccount: pair.oldAccount,
            newAccount: {account: pair.newAccount.account, relationship: action.relationship}
          };
        } else {
          return pair;
        }
      });
    }
    default:
      throw new Error("Unexpected action");
  }
}
export function MovedAccountList(props) {
  const [isLoading, setLoading] = useState(false);
  const [accounts, dispatch] = useReducer(accountsReducer, []);
  useEffect(() => {
    setLoading(true);
    props.controller.fetchMoved((accounts2) => {
      dispatch({type: "addAccounts", accounts: accounts2});
    }).finally(() => setLoading(false));
  }, []);
  async function onFollow(account) {
    const relationship = await props.controller.onfolllow(account);
    dispatch({type: "relationshipUpdated", accountId: account.id, relationship});
  }
  async function onUnFollow(account) {
    const relationship = await props.controller.onUnfollow(account);
    dispatch({type: "relationshipUpdated", accountId: account.id, relationship});
  }
  async function onRemoveFromFollowers(account) {
    const relationship = await props.controller.onRemoveFromFollowers(account);
    dispatch({type: "relationshipUpdated", accountId: account.id, relationship});
  }
  return /* @__PURE__ */ h("div", {
    className: "account-list"
  }, /* @__PURE__ */ h(ProgressIndicator, {
    loading: isLoading
  }), /* @__PURE__ */ h("h2", null, "Moved accounts:"), accounts.map((account) => {
    return /* @__PURE__ */ h(MovedAccountItem, {
      account,
      onFollow: () => onFollow(account.newAccount.account),
      onUnfollow: () => onUnFollow(account.oldAccount.account),
      onRemoveFromFollowers: () => onRemoveFromFollowers(account.oldAccount.account)
    });
  }));
}
