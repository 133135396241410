import {h} from "../_snowpack/pkg/preact.js";
import {useState} from "../_snowpack/pkg/preact/hooks.js";
export function LoginForm(props) {
  const [value, setValue] = useState("");
  function onChange(event) {
    const input = event.target;
    setValue(input.value);
  }
  function onSubmit(e) {
    e.preventDefault();
    props.onLogin(value);
  }
  return /* @__PURE__ */ h("div", {
    className: "login"
  }, /* @__PURE__ */ h("h2", null, "Refollower"), /* @__PURE__ */ h("form", {
    id: "form",
    onSubmit
  }, /* @__PURE__ */ h("label", null, "What's your server?"), /* @__PURE__ */ h("div", {
    className: "instance-picker"
  }, props.objectStorage.keys().map((instance) => /* @__PURE__ */ h("button", {
    className: "button",
    onClick: () => props.onLogin(instance)
  }, instance))), /* @__PURE__ */ h("input", {
    type: "text",
    className: "input",
    value,
    onChange
  }), /* @__PURE__ */ h("input", {
    type: "submit",
    className: "button",
    value: "Login"
  })), /* @__PURE__ */ h("h3", null, "What would happen now?"), /* @__PURE__ */ h("p", null, "After you fill in your domain name and click login your server will ask you to authorize the app. After that the list of your followers will be loaded and you can view and manage moved and old accounts."), /* @__PURE__ */ h("h3", null, "Privacy"), /* @__PURE__ */ h("p", null, "Everything is stored and accessed from your browser. We do not get access to your account."));
}
