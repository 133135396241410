import {h} from "../_snowpack/pkg/preact.js";
import {useEffect, useReducer, useState} from "../_snowpack/pkg/preact/hooks.js";
import {AccountInfo} from "./AccountInfo.js";
import {DatePicker} from "./DatePicker.js";
import {ProgressIndicator} from "./ProgressIndicator.js";
import {showSnackbar} from "./Snackbar.js";
function accountsReducer(state, action) {
  switch (action.type) {
    case "accountsLoaded":
      return state.concat(action.accounts);
    case "removed":
      return state.filter((a) => a.id !== action.accountId);
    default:
      throw new Error("Unknown action " + action);
  }
}
export function FollowerAccountList({controller}) {
  const [isLoading, setLoading] = useState(false);
  const [accounts, dispatch] = useReducer(accountsReducer, []);
  const [inactiveSince, setInactiveSince] = useState(() => {
    const d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  });
  useEffect(() => {
    setLoading(true);
    controller.fetchFollowers((batch) => dispatch({type: "accountsLoaded", accounts: batch})).finally(() => setLoading(false));
  }, []);
  async function onRemove(e, account) {
    e.stopPropagation();
    try {
      await controller.onRemoveFromFollowers(account);
      dispatch({type: "removed", accountId: account.id});
      showSnackbar("Removed " + account.acct);
    } catch (e2) {
      showSnackbar(e2.messagge);
    }
  }
  return /* @__PURE__ */ h("div", {
    className: "account-list"
  }, /* @__PURE__ */ h(ProgressIndicator, {
    loading: isLoading
  }), /* @__PURE__ */ h("h2", null, "Followers inactive since ", inactiveSince.toLocaleDateString(), ":"), /* @__PURE__ */ h("div", {
    style: "margin-bottom: 8px;"
  }, /* @__PURE__ */ h(DatePicker, {
    date: inactiveSince,
    onDateSelected: setInactiveSince
  })), accounts.map((a) => {
    const d = new Date(a.last_status_at);
    if (d > inactiveSince) {
      return null;
    }
    return /* @__PURE__ */ h("div", {
      className: "item",
      key: a.id
    }, /* @__PURE__ */ h(AccountInfo, {
      account: a
    }, /* @__PURE__ */ h("div", {
      className: "following-items"
    }, /* @__PURE__ */ h("div", {
      className: "following-active-date"
    }, a.last_status_at == null ? "--" : d.toLocaleDateString()), /* @__PURE__ */ h("button", {
      className: "text-button",
      onClick: (e) => {
        onRemove(e, a);
      }
    }, "Remove"))));
  }));
}
