import {h} from "../_snowpack/pkg/preact.js";
export function AccountInfo(props) {
  const a = props.account;
  return /* @__PURE__ */ h("div", {
    className: "account-info",
    onClick: () => window.open(a.url)
  }, props.relationship ? relationshipElement(props.relationship) : null, /* @__PURE__ */ h("img", {
    className: "avatar",
    src: a.avatar
  }), /* @__PURE__ */ h("div", {
    className: "usernames"
  }, /* @__PURE__ */ h("div", {
    className: "display-name"
  }, a.username), /* @__PURE__ */ h("div", {
    className: "display-name"
  }, a.acct)), props.children);
}
function relationshipElement(relationship) {
  let text, title;
  if (relationship.following && relationship.followed_by) {
    text = "↔";
    title = "Mutual";
  } else if (relationship.requested) {
    text = "↻";
    title = "Requested";
  } else if (relationship.following) {
    text = "→";
    title = "Following";
  } else if (relationship.followed_by) {
    text = "←";
    title = "Followed by";
  } else {
    text = " ";
    title = "Neutral";
  }
  return /* @__PURE__ */ h("div", {
    className: "relationship-icon",
    title
  }, text);
}
