import {ApiModel} from "./ApiModel.js";
export class Controller {
  constructor(objectStorage) {
    this.apiModel = new ApiModel(objectStorage);
  }
  async handleOauthRedirect() {
    const url = new URL(window.location.href);
    if (url.searchParams.has("code")) {
      const newUrl = new URL(window.location.href);
      const instance = newUrl.searchParams.get("instance");
      newUrl.searchParams.delete("code");
      newUrl.searchParams.delete("instance");
      window.history.pushState(null, "", newUrl.toString());
      await this.apiModel.fetchOauthToken(url);
      return instance;
    } else {
      return null;
    }
  }
  async loginAndFetch(instance) {
    await this.apiModel.login(instance);
  }
  async fetchMoved(onLoadedBatch) {
    const accountData = (await this.apiModel.apiFetch("api/v1/accounts/verify_credentials", "GET")).json;
    console.log("account data", accountData);
    let maxId = null;
    let total = 0;
    for (; ; ) {
      const query = {limit: 80};
      if (maxId != null) {
        query["max_id"] = maxId;
      }
      console.log("fetching with max_id", maxId);
      const followingBatch = await this.apiModel.apiFetch(`api/v1/accounts/${accountData.id}/following`, "GET", query);
      const loadedAccounts = followingBatch.json;
      total += loadedAccounts.length;
      console.log(`fetched ${loadedAccounts.length} accounts, total ${total}`);
      const moved = await this.intoPairs(loadedAccounts);
      onLoadedBatch(moved);
      if (loadedAccounts.length == 0) {
        break;
      }
      const relHeader = followingBatch.headers.get("Link");
      if (relHeader == null) {
        console.log("Link is null");
        break;
      }
      ;
      const rel = parseRelHeader(relHeader);
      const next = rel.get("next");
      if (next == null)
        break;
      const nextUrl = new URL(next);
      maxId = nextUrl.searchParams.get("max_id");
    }
  }
  async fetchFollowing(onLoadedBatch) {
    const accountData = (await this.apiModel.apiFetch("api/v1/accounts/verify_credentials", "GET")).json;
    console.log("account data", accountData);
    let maxId = null;
    let total = 0;
    for (; ; ) {
      const query = {limit: 80};
      if (maxId != null) {
        query["max_id"] = maxId;
      }
      console.log("fetching with max_id", maxId);
      const followingBatch = await this.apiModel.apiFetch(`api/v1/accounts/${accountData.id}/following`, "GET", query);
      const loadedAccounts = followingBatch.json;
      total += loadedAccounts.length;
      console.log(`fetched ${loadedAccounts.length} accounts, total ${total}`);
      onLoadedBatch(loadedAccounts);
      if (loadedAccounts.length == 0) {
        break;
      }
      const relHeader = followingBatch.headers.get("Link");
      if (relHeader == null) {
        console.log("Link is null");
        break;
      }
      ;
      const rel = parseRelHeader(relHeader);
      const next = rel.get("next");
      if (next == null)
        break;
      const nextUrl = new URL(next);
      maxId = nextUrl.searchParams.get("max_id");
    }
  }
  async fetchFollowers(onLoadedBatch) {
    const accountData = (await this.apiModel.apiFetch("api/v1/accounts/verify_credentials", "GET")).json;
    console.log("account data", accountData);
    let maxId = null;
    let total = 0;
    for (; ; ) {
      const query = {limit: 80};
      if (maxId != null) {
        query["max_id"] = maxId;
      }
      console.log("fetching with max_id", maxId);
      const followingBatch = await this.apiModel.apiFetch(`api/v1/accounts/${accountData.id}/followers`, "GET", query);
      const loadedAccounts = followingBatch.json;
      total += loadedAccounts.length;
      console.log(`fetched ${loadedAccounts.length} accounts, total ${total}`);
      onLoadedBatch(loadedAccounts);
      if (loadedAccounts.length == 0) {
        break;
      }
      const relHeader = followingBatch.headers.get("Link");
      if (relHeader == null) {
        console.log("Link is null");
        break;
      }
      ;
      const rel = parseRelHeader(relHeader);
      const next = rel.get("next");
      if (next == null) {
        console.log("next is null", relHeader);
        break;
      }
      const nextUrl = new URL(next);
      maxId = nextUrl.searchParams.get("max_id");
    }
  }
  async onfolllow(newAccount) {
    console.log("following", newAccount.acct);
    ;
    try {
      const r = await this.apiModel.apiFetch(`/api/v1/accounts/${newAccount.id}/follow`, "POST");
      console.log("followed", newAccount.acct);
      return r.json;
    } catch (e) {
      console.error("follow failed", e);
      throw e;
    }
  }
  async onUnfollow(account) {
    console.log("unfollowing", account.acct);
    try {
      const r = await this.apiModel.apiFetch(`/api/v1/accounts/${account.id}/unfollow`, "POST");
      console.log("unfollowed", account.acct);
      return r.json;
    } catch (e) {
      console.error("unfollow failed", e);
      throw e;
    }
  }
  async onRemoveFromFollowers(account) {
    console.log("blocking", account.acct);
    try {
      await this.apiModel.apiFetch(`/api/v1/accounts/${account.id}/block`, "POST");
      console.log("blocked", account.acct);
      const r = await this.apiModel.apiFetch(`/api/v1/accounts/${account.id}/unblock`, "POST");
      console.log("unblocked", account.acct);
      return r.json;
    } catch (e) {
      console.error("block/unblock failed", e);
      throw e;
    }
  }
  async intoPairs(accounts) {
    const result = [];
    for (const account of accounts) {
      if (account.moved) {
        const [oldAccount, newAccount] = await this.getRelationships([account, account.moved]);
        result.push({oldAccount, newAccount});
      }
    }
    return result;
  }
  async getRelationships(accounts) {
    const ids = accounts.map((a) => a.id);
    const relationships = (await this.apiModel.apiFetch(`api/v1/accounts/relationships`, "GET", {id: ids})).json;
    return accounts.map((account, i) => {
      return {account, relationship: relationships[i]};
    });
  }
}
function parseRelHeader(header) {
  const parts = header.split(",");
  const result = new Map();
  for (const part of parts) {
    const [urlPart, relPart] = part.split(";");
    const [relKey, relValue] = relPart.split("=");
    const rel = relValue.replace(/\s/g, "").slice(1, -1);
    const url = urlPart.replace(/\s/g, "").slice(1, -1);
    result.set(rel, url);
  }
  return result;
}
